<template>
  <div class="mb-3">
    <slot
      name="cardHeader"
      v-bind="{ handleCardToggle }"
    >
      <div
        class="flex justify-between items-center px-3 py-2 text-sm font-bold rounded-t"
        :class="[
          showCollapseButton ? 'cursor-pointer' : '',
          headerColor
        ]"
        @click="handleCardToggle()"
      >
        <slot name="title">
          <span
            :class="headerTitleColor"
            class="mr-2 text-base"
          >
            {{ title }}
          </span>
        </slot>
        <div class="flex">
          <base-svg
            v-if="showCollapseButton"
            src="icons/arrowDown.svg"
            class="transition-transform duration-200 h-5 w-5 flex-shrink-0"
            :class="[
              showCard ?'transform rotate-180': '',
              iconColor
            ]"
            tag="span"
          />
          <slot name="extraIcons" />
        </div>
      </div>
    </slot>
    <base-collapse v-model="showCard">
      <div
        class="border border-t-0 border-custom-gray-8 text-sm text-custom-gray-7 rounded-b"
        :class="cardPadding"
      >
        <slot name="cardContent" />
      </div>
    </base-collapse>
  </div>
</template>

<script>
import { defineAsyncComponent, onMounted, watch } from 'vue';
import useToggle from '@/hooks/toggle.js';

export default {
    name: 'CollapseCard',

    components: {
        BaseCollapse: defineAsyncComponent(() => import('@/components/generic-components/BaseCollapse.vue')),
        BaseSvg: defineAsyncComponent(() => import('@/components/generic-components/BaseSvg.vue'))
    },

    props: {
        title: {
            type: String,
            default: '',
            description: 'Heading of card'
        },
        headerColor: {
            type: String,
            default: 'bg-custom-gray-10'
        },
        headerTitleColor: {
            type: String,
            default: 'text-primary-red'
        },
        iconColor: {
            type: String,
            default: 'text-primary-red'
        },
        cardPadding: {
            type: String,
            default: 'px-3 py-3'
        },
        modelValue: {
            type: Boolean,
            default: false,
            description: 'bound value for toggling card'
        },
        disableCollapse: {
            type: Boolean,
            default: false,
            description: 'disables collapse toggle'
        },
        showCollapseButton: {
            type: Boolean,
            default: true,
            description: 'shows/hides collapse button in card header'
        }
    },

    setup (props) {
        // toggle card logic
        const { toggle: showCard, handleToggle: toggleCard } = useToggle();
        const handleCardToggle = () => {
            if (!props.disableCollapse) {
                toggleCard();
            }
        };
        onMounted(() => {
            showCard.value = props.modelValue;
        });
        watch(
            () => props.modelValue,
            () => {
                if (showCard.value !== props.modelValue) {
                    showCard.value = props.modelValue;
                }
            }
        );

        return {
            showCard,
            handleCardToggle
        };
    }
};
</script>
